import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3769881c = () => interopDefault(import('../pages/theme-4/download/index.vue' /* webpackChunkName: "pages/theme-4/download/index" */))
const _017834c3 = () => interopDefault(import('../pages/theme-4/games/index.vue' /* webpackChunkName: "pages/theme-4/games/index" */))
const _41521362 = () => interopDefault(import('../pages/theme-4/hold-balance/index.vue' /* webpackChunkName: "pages/theme-4/hold-balance/index" */))
const _b9fc9582 = () => interopDefault(import('../pages/theme-4/leaderboard/index.vue' /* webpackChunkName: "pages/theme-4/leaderboard/index" */))
const _276986e8 = () => interopDefault(import('../pages/theme-4/login.vue' /* webpackChunkName: "pages/theme-4/login" */))
const _758c3fd4 = () => interopDefault(import('../pages/theme-4/no-support.vue' /* webpackChunkName: "pages/theme-4/no-support" */))
const _60a1d087 = () => interopDefault(import('../pages/theme-4/not-found.vue' /* webpackChunkName: "pages/theme-4/not-found" */))
const _397dbdea = () => interopDefault(import('../pages/theme-4/play-game.vue' /* webpackChunkName: "pages/theme-4/play-game" */))
const _b817beb6 = () => interopDefault(import('../pages/theme-4/promotion/index.vue' /* webpackChunkName: "pages/theme-4/promotion/index" */))
const _0054a0bc = () => interopDefault(import('../pages/theme-4/recently/index.vue' /* webpackChunkName: "pages/theme-4/recently/index" */))
const _c8b32842 = () => interopDefault(import('../pages/theme-4/refer-friend/index.vue' /* webpackChunkName: "pages/theme-4/refer-friend/index" */))
const _2f9b48f4 = () => interopDefault(import('../pages/theme-4/register.vue' /* webpackChunkName: "pages/theme-4/register" */))
const _29e9dab9 = () => interopDefault(import('../pages/theme-4/download/android.vue' /* webpackChunkName: "pages/theme-4/download/android" */))
const _f67cb352 = () => interopDefault(import('../pages/theme-4/download/ios.vue' /* webpackChunkName: "pages/theme-4/download/ios" */))
const _0ffff876 = () => interopDefault(import('../pages/theme-4/player/deposits/index.vue' /* webpackChunkName: "pages/theme-4/player/deposits/index" */))
const _6313daf8 = () => interopDefault(import('../pages/theme-4/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/index" */))
const _29fba972 = () => interopDefault(import('../pages/theme-4/promotion/bonus.vue' /* webpackChunkName: "pages/theme-4/promotion/bonus" */))
const _7a8ff34f = () => interopDefault(import('../pages/theme-4/player/deposits/bank-transfer.vue' /* webpackChunkName: "pages/theme-4/player/deposits/bank-transfer" */))
const _1b8660d2 = () => interopDefault(import('../pages/theme-4/player/deposits/crypto-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/crypto-payment" */))
const _60b2ca29 = () => interopDefault(import('../pages/theme-4/player/deposits/online-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/online-payment" */))
const _459a8419 = () => interopDefault(import('../pages/theme-4/player/deposits/quick-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/quick-payment" */))
const _23cbcc18 = () => interopDefault(import('../pages/theme-4/player/withdrawals/bank-withdrawal.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/bank-withdrawal" */))
const _de8b2f1a = () => interopDefault(import('../pages/theme-4/player/withdrawals/crypto-withdrawal.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/crypto-withdrawal" */))
const _5dc1e3d1 = () => interopDefault(import('../pages/theme-4/index.vue' /* webpackChunkName: "pages/theme-4/index" */))
const _443bc943 = () => interopDefault(import('../pages/theme-4/_type/_id.vue' /* webpackChunkName: "pages/theme-4/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/download",
    component: _3769881c,
    name: "download"
  }, {
    path: "/games",
    component: _017834c3,
    name: "games"
  }, {
    path: "/hold-balance",
    component: _41521362,
    name: "hold-balance"
  }, {
    path: "/leaderboard",
    component: _b9fc9582,
    name: "leaderboard"
  }, {
    path: "/login",
    component: _276986e8,
    name: "login"
  }, {
    path: "/no-support",
    component: _758c3fd4,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _60a1d087,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _397dbdea,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _b817beb6,
    name: "promotion"
  }, {
    path: "/recently",
    component: _0054a0bc,
    name: "recently"
  }, {
    path: "/refer-friend",
    component: _c8b32842,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _2f9b48f4,
    name: "register"
  }, {
    path: "/download/android",
    component: _29e9dab9,
    name: "download-android"
  }, {
    path: "/download/ios",
    component: _f67cb352,
    name: "download-ios"
  }, {
    path: "/player/deposits",
    component: _0ffff876,
    name: "player-deposits"
  }, {
    path: "/player/withdrawals",
    component: _6313daf8,
    name: "player-withdrawals"
  }, {
    path: "/promotion/bonus",
    component: _29fba972,
    name: "promotion-bonus"
  }, {
    path: "/player/deposits/bank-transfer",
    component: _7a8ff34f,
    name: "player-deposits-bank-transfer"
  }, {
    path: "/player/deposits/crypto-payment",
    component: _1b8660d2,
    name: "player-deposits-crypto-payment"
  }, {
    path: "/player/deposits/online-payment",
    component: _60b2ca29,
    name: "player-deposits-online-payment"
  }, {
    path: "/player/deposits/quick-payment",
    component: _459a8419,
    name: "player-deposits-quick-payment"
  }, {
    path: "/player/withdrawals/bank-withdrawal",
    component: _23cbcc18,
    name: "player-withdrawals-bank-withdrawal"
  }, {
    path: "/player/withdrawals/crypto-withdrawal",
    component: _de8b2f1a,
    name: "player-withdrawals-crypto-withdrawal"
  }, {
    path: "/",
    component: _5dc1e3d1,
    name: "index"
  }, {
    path: "/:type/:id?",
    component: _443bc943,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
